import React, { useEffect, useState } from 'react'
import events from '../events.json'
// import { compose, graphql } from 'react-apollo'

import RunnerPhotos from '../components/RunnerPhotos';
import RunnerFollowMe from '../components/RunnerFollowMe';
import RunnerEcer from '../components/ecer/RunnerEcer';
import RunnereSlip from '../components/eslip/RunnereSlip';
import RunnerFaceX from '../components/RunnerFaceX';
import RunnerTop from '../components/RunnerTop';

// import getTemplateIdQuery from '~/graphql/queries/getTemplateId.gql'


// const apiTOPUri = process.env.REACT_APP_TOP_API_URI


// const onImageError = (event) => {
//   event.target.src = '/images/not_found.png'
// }
// const adjustIframe = (event) => {
//   // obj.style.height = 0
//   event.target.style.height = event.target.contentWindow.document.body.scrollHeight + 'px';
// }

const EventRunner = ({ match, data }) => {
  const { eventId, bib } = match.params
  const bibparseInt = parseInt(bib, 10).toString()
  // console.log({bibparseInt})
  const event = events.find(e => e._id === eventId)
  const [pkrs3Data, setPkrs3Data] = useState(null)
  console.log(pkrs3Data && pkrs3Data.result_link)
  useEffect(() => {
    const fetchPkrs4Data = async () => {
      const records = await fetch('/pkrs3.json').then(res => res.json())
      setPkrs3Data(records.find(r => r.BIB === parseInt(bib, 10)))
    }
    if (eventId === 'PKRS3') {
      fetchPkrs4Data()
    }
  }, [])
  if (!event) {
    return <div>Event Not Found</div>
  }
  const { photoEventSlug, eSlipEventSlug, eCerEventSlug, followMeEventId, faceXEventSlug, topEventSlug, topIndex  } = event
  return <div>
    <h1 className="header">
      <img className="logo" src="/images/logo.png" alt="RUNNER.THAI.RUN" />
      RUNNER.THAI.RUN
    </h1>
    <div style={{ textAlign:'center' }}>
      {faceXEventSlug && <RunnerFaceX slug={faceXEventSlug} bib={bibparseInt} />}
    </div>
    <div className="bib">{bib}</div>
    <div className="content">
      <div className="title">
        <div className="titleTh">{event.title.th}</div>
        <div className="titleEn">{event.title.en}</div>
      </div>
      {eventId === 'PKRS3' && pkrs3Data && (
        <iframe title="Prachuap Khiri Run Season3" src={pkrs3Data.result_link} frameBorder={0} width="320px" height="1440px" style={{ width: 320, height: 1440 }}/>
        // <iframe title="Prachuap Khiri Run Season3" src={pkrs3Data.result_link} onLoad={adjustIframe} width="320px" height="1024px" style={{ width: 320, height: 1024 }}/>
      // <React.Fragment>
      //   <div style={{ textAlign: 'center' }}>
      //     <h2 className="sectionTitle">
      //       eSlip
      //     </h2>
      //     <a href={pkrs3Data.result_link} target="_blank"><img src={`https://runner.primeworks.asia/res/51908/eslip/${parseInt(bib, 10)}.png`} alt="eSlip" onError={onImageError} /></a>
      //   </div>
      //   <div style={{ textAlign: 'center' }}>
      //     <h2 className="sectionTitle">
      //       eCer
      //     </h2>
      //     <a href={pkrs3Data.result_link} target="_blank"><img src={`https://runner.primeworks.asia/res/51908/ecer/${parseInt(bib, 10)}.png`} alt="eCer" onError={onImageError} /></a>
      //   </div>
      // </React.Fragment>
      )}
      {followMeEventId && <RunnerFollowMe slug={followMeEventId}/>}
      {/* {topEventSlug &&  <RunnerTop templateId={templateId} bib={bib}/>} */}
      {topEventSlug &&  <RunnerTop slug={topEventSlug} bib={bibparseInt} index={topIndex}/>}
      <div className="ecerEslip">
        <div className="ecer">
          {eCerEventSlug && <RunnerEcer slug={eCerEventSlug} bib={bib} />}
        </div>
        <div className="eslip">
          {eSlipEventSlug && <RunnereSlip slug={eSlipEventSlug} bib={bib} />}
        </div>
      </div>
      {photoEventSlug && <RunnerPhotos slug={photoEventSlug} bib={bib}/>}
    </div>
  </div>
}

export default EventRunner
