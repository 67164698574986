import React, { Component }  from 'react'
import { compose, graphql } from 'react-apollo'
import getUri from '~/graphql/queries/getUri.gql'

const apiUri = process.env.REACT_APP_ECER_API_URI

class RunnerGetESlip extends Component {
  render() {
    const slug = this.props.slug
    const bib = this.props.bib
    if (!this.props.data.time) {
      return <div>
        <h2 className="sectionTitle">
          e-Slip
        </h2>
        <div>ไม่พบ e-Slip ของท่าน กรุณาค้นหาที่ <a href={`https://ecer.thai.run/${slug}`} target="_blank">ECER.THAI.RUN</a></div>
      </div>
    } 
    const time = this.props.data.time
    return (
      <div>
        <h2 className="sectionTitle">
          eSlip
        </h2>
        <div>
          <a href={`https://ecer.thai.run/${slug}/${bib}`} ><img src={time.link} alt="img" style={{width:'334px', maxWidth:'100%' }} /></a>
        </div>
      </div>
    )
  }
}

export default compose(
  graphql(getUri, {
    options: (props) => ({
      variables: {
        slug : props.slug,
        bib : props.bib
      },
      context: {
        uri: apiUri
      }
    })
  })
)(RunnerGetESlip)