import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Home from './pages/Home'
import Event from './pages/Event'
import EventRunner from './pages/EventRunner'
import NotFound from './pages/NotFound'

const App = () => 
<Switch>
  <Route path="/:eventId/:bib" component={EventRunner} />
  <Route path="/:eventId" component={Event} />
  <Route path="/" component={Home} />
  <Route component={NotFound} />
</Switch>

export default App
