import React, { Component }  from 'react'
import 'antd/dist/antd.css';
import { Button } from 'antd';


class RunnerFollowMe extends Component {
  render() {
    const slug = this.props.slug
    return(
      <div className="follow" >
        <a target="_blank" href={`https://followme.thai.run/${slug}`} >
          <Button type="primary" size="large">ลงทะเบียน followMe</Button>
        </a>
      </div>
    )
  }
}

export default (RunnerFollowMe)