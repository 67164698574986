import React, { Component }  from 'react'
import { compose, graphql } from 'react-apollo'
import queryEvent from '~/graphql/queries/queryEvent.gql'
import RunnerGeteSlip from './RunnerGeteSlip'

const apiUri = process.env.REACT_APP_ECER_API_URI

class RunnereSlip extends Component {
  render() {
    if(!this.props.data.event){
      return <div className="ecer" >Loading...RunnerEcereSlip</div>
    } 
    const date = this.props.data.event
    const event = date.event
    const slug = this.props.slug
    const databib = this.props.bib
    const bib = parseInt(databib, 10)
    return(
        <div>
          {event && <RunnerGeteSlip event={event} slug={slug} bib={bib}/>}
        </div>
    )
  }
}

export default compose(
  graphql(queryEvent, {
    options: ({ slug }) => ({
      variables: {
        slug,
      },
      context: {
        uri: apiUri
      }
    })
  })
)(RunnereSlip)