import React, { Component }  from 'react'
import 'antd/dist/antd.css';
// import request from 'request-promise';
import { Button } from 'antd';
import { compose, graphql } from 'react-apollo'

import getTemplateIdQuery from '~/graphql/queries/getTemplateId.gql'


const apiTOPUri = process.env.REACT_APP_TOP_API_URI

class RunnerTop extends Component {
  state = {
    button: false,
  }

  render() {
    const { event } = this.props.data
    if(!event) return <div>loading...</div>

    const { bib, index }  = this.props
    const link = `${event.apiUri}?filter=(%5BATF1%5D=${bib})`

    fetch(link).then(res => res.json()).then(response => {
      const [value] = response
      // const [value] = JSON.parse(res)
      if(value[index] !== '-') {
        this.setState({ button: true })
      }
      // console.log(value[21])
    })
    return(
      <div className="follow" >
         { this.state.button ? 
            <Button
              style={{ 
                width: "300px", 
                height: "60px", 
                color: "white", 
                borderRadius: "30px", 
                backgroundColor: "#f5222d",
                borderColor : "#ff4d4f"
              }} 
            >
                คุณได้รับรางวัล Top 100
            </Button>
          : ''}
      </div>
    )
  }
}

export default compose(
    graphql(getTemplateIdQuery, {
      options: (props) => ({
        variables: {
          slug : props.slug
        },
        context: {
          uri: apiTOPUri
        }
      })
    })
)(RunnerTop)