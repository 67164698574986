import React, { Component }  from 'react'
import { compose, graphql } from 'react-apollo'
import participantsQuery from '~/graphql/queries/participants.gql'

const apiFACEXUri = process.env.REACT_APP_FACEX_API_URI

class RunnerFaceX extends Component {
  render() {
    if (!this.props.data.participantPhoto) {
      return <div>
      </div>
    } 
    const photoData = this.props.data.participantPhoto.photo
    return (
        <div>
          <img src={photoData} 
            style={{ 
              maxWidth: "100%", 
              width: "200px", 
              maxHeight: "100%", 
              height: "200px", 
              marginTop: '10px', 
              marginBottom: "-20px", 
              borderRadius: "50%", 
              objectFit: "cover" 
            }} 
            alt="logoFooter"
          />
        </div>
    )
  }
}

export default compose(
    graphql(participantsQuery, {
        options: (props) => ({
          variables: {
            bib : props.bib,
            slug : props.slug
          },
          context: {
            uri: apiFACEXUri
          }
        })
      })
)(RunnerFaceX)